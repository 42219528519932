import React from "react";
import {
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Box,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 300,
    margin: "0 auto",
    animation: `$Slide 1.5s ${theme.transitions.easing.easeInOut}`,
  },
  "@keyframes Slide": {
    "0%": {
      opacity: 0,
      transform: "translateX(100%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
  link: {
    textDecoration: "none !important",
    color: theme.palette.foreground.default,
  },
  media: {
    height: 200,
  },
}));

const OurWorks = () => {
  const classes = useStyles();

  const works = [
    {
      title: "Geminify: Your Browsing Assistant",
      image: "geminify.png",
      link: "https://chromewebstore.google.com/detail/geminify/alegbajeogbdlcklpnlihhbklgienpoj",
      description:
        "Geminify is a browser extension that helps you summarize or query the content of a webpage using Google's Gemini API.",
    },
    {
      title: "Zara: The Legal Advisor",
      image: "zara.jpg",
      link: "https://github.com/Hulmify/Zara-The-Legal-Advisor",
      description:
        "This FastAPI application serves as a backend for a legal assistant chatbot named Zara, powered by a Llama-based language model. Zara is designed to handle legal queries, maintain conversation history, and provide responses in Markdown format.",
    },
    {
      title: "Lucid: Smart Upsell & Cross‑Sell",
      image: "lucid.png",
      link: "https://apps.shopify.com/lucid-by-hulmify",
      description:
        "Boost your sales and AoV with personalized & smart product recommendations, bundles, and promotions.",
    },
  ];

  return (
    <Box mb={2}>
      <Box mb={4}>
        <Typography variant="h4" align="center" gutterBottom>
          Our Works
        </Typography>
        <Typography
          variant="caption"
          align="center"
          gutterBottom
          textAlign="center"
          component="p"
        >
          Below is a chronological list of projects we have worked on, starting
          with the most recent.
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {works.map((work, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              className={classes.card}
              style={{
                height: "100%",
              }}
            >
              <Link
                href={work.link}
                target="_blank"
                className={classes.link}
                rel="noopener noreferrer"
              >
                <CardMedia
                  className={classes.media}
                  image={work.image}
                  title={work.title}
                />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {work.title}
                  </Typography>
                  <Typography variant="body2">{work.description}</Typography>
                </CardContent>
              </Link>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OurWorks;
