import React from "react";
import { Typography, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  workCard: {
    animation: `$Slide 1.5s ${theme.transitions.easing.easeInOut}`,
  },
  icon: {
    fontSize: "3rem",
    marginBottom: "1rem",
    color: theme.palette.foreground.default,
  },
  "@keyframes Slide": {
    "0%": {
      opacity: 0,
      transform: "translateX(-100%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
}));

const WhatWeDo = () => {
  const classes = useStyles();

  const works = [
    {
      title: "Software",
      icon: "fas fa-code",
      description: "Software solutions tailored to user-centric goals.",
    },
    {
      title: "Web Applications",
      icon: "fas fa-desktop",
      description:
        "Responsive, secure and scalable web applications using modern technologies.",
    },
    {
      title: "Mobile Applications",
      icon: "fas fa-mobile",
      description:
        "Native and cross-platform mobile applications for iOS and Android.",
    },
    {
      title: "AI",
      icon: "fas fa-robot",
      description:
        "Leverage artificial intelligence technologies to drive innovation and automation.",
    },
    {
      title: "Cloud Computing",
      icon: "fas fa-cloud",
      description: "Configure and deploy cloud infrastructure to scale.",
    },
    {
      title: "Design",
      icon: "fas fa-palette",
      description: "Create intuitive and engaging digital experiences.",
    },
  ];

  return (
    <Box mb={2}>
      <Box mb={4}>
        <Typography variant="h4" align="center" gutterBottom>
          What We Do
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {works.map((work, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            className={classes.workCard}
          >
            <Box textAlign="center">
              <i className={work.icon + " " + classes.icon}></i>
              <Typography variant="h6" gutterBottom>
                {work.title}
              </Typography>
              <Typography variant="body2">{work.description}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WhatWeDo;
